import * as React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/main.less';

// markup
const DownloadPage = () => {
  return (
      <div>
        <Helmet htmlAttributes={{lang: 'de'}}>
          <meta charSet="utf-8"/>
          <meta name="robots" content="noindex"/>
          <title>Downloads | Kinderosteopathie Karin Eideloth</title>
          <link rel="canonical" href="https://kinderosteopathie.bayern/downloads"/>
        </Helmet>

        <Header/>

        <main className={'main main--downloads'}>
          <section className="section section--downloads">
            <div className="section__content">

              <h1 class="heading heading--section">
                Downloads
              </h1>

              <p class={'section__paragraph'}>

                  <ul class={'list list--align-center'}>
                      <li><a href={'/files/Kinderosteopathie_Eideloth_Formulare.pdf'} download >Alle Formulare</a></li>
                      <li><a href={'/files/Kinderosteopathie_Eideloth_Aufnahmebogen.pdf'} download >Aufnahmebogen</a></li>
                      <li><a href={'/files/Kinderosteopathie_Eideloth_Datenschutzerklaerung.pdf'} download >Datenschutzerklärung</a></li>
                      <li><a href={'/files/Kinderosteopathie_Eideloth_Patientenaufklaerung.pdf'} download >Patientenaufklärung</a></li>
                  </ul>

              </p>

            </div>
          </section>

        </main>
        <Footer/>
      </div>
  );
};

export default DownloadPage;
